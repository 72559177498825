import {pick} from 'lodash'
import {makeSetters} from '@helpers/vuex/mutations'

const defaults = {
  name: null,
  cropId: null,
  note: null,
  quality: null,
  bsA_Culture: null,
  bsA_Culture_Short: null,
  bsA_Number: null,
  minSeedRate: null,
  maxSeedRate: null,
  maizeMatrix: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  mixtureComponents: []
}

export default {
  namespaced: true,
  state: {
    product: {
      ...defaults
    }
  },
  mutations: {
    ...makeSetters([
      'product.name',
      'product.cropId',
      'product.note',
      'product.quality',
      'product.bsA_Culture',
      'product.bsA_Culture_Short',
      'product.bsA_Number',
      'product.minSeedRate',
      'product.maxSeedRate',
      'product.maizeMatrix',
      'product.mixtureComponents'
    ]),
    initProduct (state, product) {
      state.product = pick(product, ['id', 'name', 'cropId', 'note', 'quality', 'bsA_Culture', 'bsA_Culture_Short', 'bsA_Number', 'minSeedRate', 'maxSeedRate', 'maizeMatrix', 'mixtureComponents'])
    },
    reset (state) {
      state.product = {...defaults}
    }
  }
}
