<template>
  <div>
    <CollapsibleSection v-model="showSeedDetails">
      <template #header>
        <IxRes>masterData.seed.productDetails</IxRes>
      </template>
      <SeedDetailsForm
        v-model="seedDetails"
      />
    </CollapsibleSection>

    <CollapsibleSection v-if="isMaize" v-model="showMaizeDetails">
      <template #header>
        <IxRes>masterData.seed.maize</IxRes>
      </template>
      <MaizeDetailsForm
        v-model="maizeDetails"
        :selected-product-id="selectedProductId"
      />
    </CollapsibleSection>

    <CollapsibleSection v-if="!isMaize" v-model="showOfficialDetails">
      <template #header>
        <IxRes>masterData.seed.BSA</IxRes>
      </template>
      <OfficialSeedDetailsForm
        v-model="officialSeedDetails"
      />
    </CollapsibleSection>

    <CollapsibleSection v-model="showMixtureDetails">
      <template #header>
        <IxRes>masterData.seed.mixture</IxRes>
      </template>
      <MixtureForm
        v-model="mixtureComponents"
      />
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {extractFormModelsFromProperties} from '@helpers/vue'
import {isMaize} from '@helpers/products'

import CollapsibleSection from '@components/CollapsibleSection'
import SeedDetailsForm from '@md/components/widgets/SeedDetailsForm'
import OfficialSeedDetailsForm from '@md/components/widgets/OfficialSeedDetailsForm'
import MaizeDetailsForm from '@md/components/widgets/MaizeDetailsForm'
import MixtureForm from '@md/components/widgets/MixtureForm'

export default {
  components: {
    CollapsibleSection,
    SeedDetailsForm,
    OfficialSeedDetailsForm,
    MaizeDetailsForm,
    MixtureForm
  },
  data () {
    return {
      showSeedDetails: true,
      showOfficialDetails: false,
      showMaizeDetails: false,
      showMixtureDetails: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/products', [
      'selectedProductId'
    ]),
    ...mapFormFields('fieldRecordSystem/products/editBuffer', [
      'product.name',
      'product.cropId',
      'product.note',
      'product.quality',
      'product.bsA_Culture',
      'product.bsA_Culture_Short',
      'product.bsA_Number',
      'product.minSeedRate',
      'product.maxSeedRate',
      'product.maizeMatrix',
      'product.mixtureComponents'
    ]),
    ...extractFormModelsFromProperties({
      seedDetails: ['name', 'cropId', 'note', 'quality'],
      officialSeedDetails: ['bsA_Culture', 'bsA_Culture_Short', 'bsA_Number'],
      maizeDetails: ['minSeedRate', 'maxSeedRate', 'maizeMatrix']
    }),
    isMaize () {
      return isMaize({cropId: this.cropId})
    }
  },
  created () {
    if (this.mixtureComponents.length > 0) {
      this.showMixtureDetails = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
