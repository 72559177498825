<template>
  <div class="action-buttons">
    <IxButton
      save large
      @click="$emit('save')"
    />
    <IxButton
      cancel large
      @click="$emit('cancel')"
    />
  </div>
</template>

<script>
import IxButton from 'src/vue/components/IxButton'

export default {
  components: {
    IxButton
  }
}
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
