<template>
  <div class="product-details-component">
    <component
      :is="component" v-if="bufferInitialized"
      :key="component"
      class="details-component"
    />
    <HelpBox v-if="fieldStates.mixture === 'error'" type="danger">
      <IxRes>masterData.mixture.components.incomplete</IxRes>
    </HelpBox>
    <ActionButtons class="action-buttons" @cancel="onCancel" @save="onSaveProduct" />
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

import {mapResources} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'
import HelpBox from '@components/help/HelpBox'

import SeedDetails from './SeedDetails'
import ActionButtons from './ActionButtons'
import seedBuffer from '@store/modules/field-record-system/products/edit-buffer-seed'

export default {
  components: {
    SeedDetails,
    ActionButtons,
    HelpBox
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      bufferInitialized: false
    }
  },
  computed: {
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapState('masterData', [
      'customProducts'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapState('fieldRecordSystem/products', [
      'selectedGroup'
    ]),
    ...mapState('fieldRecordSystem/products/editBuffer', [
      'product'
    ]),
    component () {
      switch (this.selectedGroup) {
      case 'seed': return 'SeedDetails'
      default: throw new Error('product type unknown')
      }
    },
    buffer () {
      switch (this.selectedGroup) {
      case 'seed': return seedBuffer
      default: throw new Error('product type unknown')
      }
    },
    products () {
      return this.customProducts[this.orgUnitId]
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/products/editBuffer', [
      'initProduct',
      'reset'
    ]),
    ...mapActions('fieldRecordSystem/products', [
      'saveProduct'
    ]),
    selectProduct (from, to) {
      if (!this.$store.state.fieldRecordSystem.products['editBuffer']) {
        this.$store.registerModule(['fieldRecordSystem', 'products', 'editBuffer'], this.buffer)
      }

      const product = this.products[to.params.productId]
      if (product) {
        this.initProduct(product)
      }

      this.bufferInitialized = true // ugly but solves timing problems when dynamically loading edit buffer
    },
    onCancel () {
      this.$router.push({name: 'product-management'})
    },
    async onSaveProduct () {
      this.validate()
      if (this.selectedGroup === 'seed') {
        this.validateMixture()
      }

      await this.$nextTick()
      if (this.state === 'error') return

      this.isLoading = true
      try {
        await this.saveProduct()
        notifications.success(this.SR_StateMessages.SaveSuccess)
        this.$router.push({name: 'product-management'})
      } catch (error) {
        notifications.error(this.SR_StateMessages.SaveError)
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    validateMixture () {
      if (this.product.mixtureComponents.length > 0) {
        const sum = this.product.mixtureComponents.reduce((sum, x) => sum + x.percentage, 0)
        this.$set(this.fieldStates, 'mixture', Number.parseFloat(sum.toFixed(4)) === 1 ? 'success' : 'error')
      } else {
        this.$delete(this.fieldStates, 'mixture')
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.selectProduct(from, to)
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.$store.state.fieldRecordSystem.products['editBuffer']) {
      this.reset()
      this.$store.unregisterModule(['fieldRecordSystem', 'products', 'editBuffer'])
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.product-details-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 5px;

  .details-component {
    flex: 1;
    overflow: auto;
  }
}
</style>
